import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './api/redux/configStore'
import './index.css'
import './utils/lang/i18n'
import 'bootstrap/dist/css/bootstrap.min.css'
import LoadingPopup from './components/LoadingPopup/LoadingPopup'
const App = lazy(() => import('./App'))
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<LoadingPopup />}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
